import { Injectable } from '@angular/core';

import { NumberUnit, ThemeService } from '@shared/utils';
import { MobilityStats } from '@upscore-mobility-audit/api';
import { DistributionTableComponentEntry } from '@upscore-mobility-audit/kpi/interfaces/distribution-table-component-entry.interface';
import { TopicTableComponentEntry } from '@upscore-mobility-audit/kpi/interfaces/topic-table-component-entry.interface';
import { MobilityScenario } from '@upscore-mobility-audit/shared/interfaces/mobility-scenario.interface';
import { UtilsService } from '@upscore-mobility-audit/shared/services/utils.service';
import { KpiGridServiceTranslations } from '@upscore-mobility-audit/shared/translations/kpi-grid-service-translations';

@Injectable({
    providedIn: 'root',
})
export class KpiGridService {
    constructor(
        private readonly themeService: ThemeService,
        private readonly utilsService: UtilsService,
    ) {}

    /**
     * init bar distribution
     * @param numbers
     */
    public getBarDistribution(numbers: number[] | undefined): { width: number; color: string }[] {
        const sum: number = this.utilsService.getSumOfArray(numbers);

        const colors = [
            this.themeService.getHexFromCSSVariable('--walk-color'),
            this.themeService.getHexFromCSSVariable('--bike-color'),
            this.themeService.getHexFromCSSVariable('--public-transport-color'),
            this.themeService.getHexFromCSSVariable('--car-driver-color'),
            this.themeService.getHexFromCSSVariable('--car-passenger-color'),
        ];

        return colors.map((color, index) => ({
            width: (numbers?.[index] || 0) / sum,
            color: color,
        }));
    }

    /**
     * init company distribution table
     */
    public initDistributionTable(
        numbers: number[] | undefined,
        numbersDaily: number[] | undefined,
    ): DistributionTableComponentEntry[] {
        const sum: number = this.utilsService.getSumOfArray(numbers);
        const titlesAndColors = [
            {
                title: KpiGridServiceTranslations.distributionTableWalkTitle,
                color: this.themeService.getHexFromCSSVariable('--walk-color'),
            },
            {
                title: KpiGridServiceTranslations.distributionTableBikeTitle,
                color: this.themeService.getHexFromCSSVariable('--bike-color'),
            },
            {
                title: KpiGridServiceTranslations.distributionTablePTTitle,
                color: this.themeService.getHexFromCSSVariable('--public-transport-color'),
            },
            {
                title: KpiGridServiceTranslations.distributionTableCarDriverTitle,
                color: this.themeService.getHexFromCSSVariable('--car-driver-color'),
            },
            {
                title: KpiGridServiceTranslations.distributionTableCarPassengerTitle,
                color: this.themeService.getHexFromCSSVariable('--car-passenger-color'),
            },
        ];

        return titlesAndColors.map(({ color, title }, index) => ({
            title,
            yearly: numbers?.[index] || 0,
            distribution: (numbers?.[index] || 0) / sum,
            color,
            daily: numbersDaily?.[index] || 0,
        }));
    }

    /**
     * Method to init average employee topic entries
     * @param mobilityScenario
     * @param employeeCount
     * @param baselineMobilityScore
     */
    public initAverageEmployeeTopicEntries(
        mobilityScenario: MobilityScenario,
        employeeCount: number,
        baselineMobilityScore?: MobilityScenario | null,
    ): TopicTableComponentEntry[] {
        let currEmployerCost = 0;
        let currEmployerCostsDaily = 0;

        let baselineEmployerCost: number | undefined = undefined;
        let baselineEmployerCostsDaily: number | undefined = undefined;

        if (mobilityScenario.scoreType === 'MobilityScore') {
            currEmployerCost = mobilityScenario.yearlyEmployerCosts / employeeCount;
            currEmployerCostsDaily = mobilityScenario.dailyEmployerCosts / employeeCount;
        }

        if (baselineMobilityScore?.scoreType === 'MobilityScore') {
            baselineEmployerCost = baselineMobilityScore.yearlyEmployerCosts / employeeCount;
            baselineEmployerCostsDaily = baselineMobilityScore.dailyEmployerCosts / employeeCount;
        }

        return [
            {
                title: KpiGridServiceTranslations.averageEmployeeTopicEntryTitleCO2Emissions,
                yearly:
                    this.utilsService.getSumOfArray(mobilityScenario.yearlyEmissions) /
                    employeeCount,
                daily:
                    this.utilsService.getSumOfArray(mobilityScenario.dailyEmissions) /
                    employeeCount,
                baselineYearly: baselineMobilityScore
                    ? this.utilsService.getSumOfArray(baselineMobilityScore.yearlyEmissions) /
                      employeeCount
                    : undefined,
                baselineDaily: baselineMobilityScore
                    ? this.utilsService.getSumOfArray(baselineMobilityScore.dailyEmissions) /
                      employeeCount
                    : undefined,
                unit: NumberUnit.WEIGHT,
                digitsInfo: '1.0-2',
            },
            {
                title: KpiGridServiceTranslations.averageEmployeeTopicEntryTitleEmployeeCosts,
                yearly:
                    this.utilsService.getSumOfArray(mobilityScenario.yearlyEmployeeCosts) /
                    employeeCount,
                daily:
                    this.utilsService.getSumOfArray(mobilityScenario.dailyEmployeeCosts) /
                    employeeCount,
                baselineYearly: baselineMobilityScore
                    ? this.utilsService.getSumOfArray(baselineMobilityScore.yearlyEmployeeCosts) /
                      employeeCount
                    : undefined,
                baselineDaily: baselineMobilityScore
                    ? this.utilsService.getSumOfArray(baselineMobilityScore.dailyEmployeeCosts) /
                      employeeCount
                    : undefined,
                unit: NumberUnit.MONEY,
                digitsInfo: '1.0-2',
            },
            {
                title: KpiGridServiceTranslations.averageEmployeeTopicEntryTitleBusinessCosts,
                yearly: currEmployerCost,
                daily: currEmployerCostsDaily,
                baselineYearly: baselineEmployerCost,
                baselineDaily: baselineEmployerCostsDaily,
                unit: NumberUnit.MONEY,
                digitsInfo: '1.0-2',
            },
            {
                title: KpiGridServiceTranslations.averageEmployeeTopicEntryTitleTravelTime,
                yearly:
                    this.utilsService.getSumOfArray(mobilityScenario.yearlyTripDuration) /
                    employeeCount,
                daily:
                    this.utilsService.getSumOfArray(mobilityScenario.dailyTripDuration) /
                    employeeCount,
                baselineYearly: baselineMobilityScore
                    ? this.utilsService.getSumOfArray(baselineMobilityScore.yearlyTripDuration) /
                      employeeCount
                    : undefined,
                baselineDaily: baselineMobilityScore
                    ? this.utilsService.getSumOfArray(baselineMobilityScore.dailyTripDuration) /
                      employeeCount
                    : undefined,
                unit: NumberUnit.TIME,
                digitsInfo: '1.0-2',
            },
            {
                title: KpiGridServiceTranslations.averageEmployeeTopicEntryTitleTravelDistance,
                yearly:
                    this.utilsService.getSumOfArray(mobilityScenario.yearlyTripDistance) /
                    employeeCount,
                daily:
                    this.utilsService.getSumOfArray(mobilityScenario.dailyTripDistance) /
                    employeeCount,
                baselineYearly: baselineMobilityScore
                    ? this.utilsService.getSumOfArray(baselineMobilityScore.yearlyTripDistance) /
                      employeeCount
                    : undefined,
                baselineDaily: baselineMobilityScore
                    ? this.utilsService.getSumOfArray(baselineMobilityScore.dailyTripDistance) /
                      employeeCount
                    : undefined,
                unit: NumberUnit.DISTANCE,
                digitsInfo: '1.0-2',
            },
        ].filter(
            (t: TopicTableComponentEntry) =>
                t.title !==
                    KpiGridServiceTranslations.averageEmployeeTopicEntryTitleBusinessCosts ||
                (t.title ===
                    KpiGridServiceTranslations.averageEmployeeTopicEntryTitleBusinessCosts &&
                    t.yearly != null &&
                    t.yearly !== 0) ||
                (t.title ===
                    KpiGridServiceTranslations.averageEmployeeTopicEntryTitleBusinessCosts &&
                    t.baselineYearly != null &&
                    t.baselineYearly !== 0),
        );
    }

    /**
     * Method to init the modal split topic entries
     * @param values
     * @param dailyValues
     * @param unit
     * @param employeeCount
     * @param mobilityStats
     * @param compareValuesYearly
     * @param compareValuesDaily
     * @param compareMobilityStats
     */
    public initModalSplitTopicEntries(
        values: number[] | [],
        dailyValues: number[] | [],
        unit: NumberUnit,
        employeeCount: number,
        mobilityStats: MobilityStats | undefined,
        compareValuesYearly?: number[],
        compareValuesDaily?: number[],
        compareMobilityStats?: MobilityStats,
    ): TopicTableComponentEntry[] {
        const calculateTopicValues = (
            value: number | undefined,
            employeeCount: number,
            mobilityStats: MobilityStats | undefined,
            type: keyof MobilityStats,
            defaultValue = 0,
        ) => {
            if (!mobilityStats || !mobilityStats[type] || !employeeCount) {
                return defaultValue;
            }

            return (value || 0) / (employeeCount * mobilityStats[type]);
        };

        return [
            {
                title: KpiGridServiceTranslations.modalSplitTopicEntryTitleWalk,
                yearly: calculateTopicValues(values?.[0], employeeCount, mobilityStats, 'walk'),
                daily: calculateTopicValues(dailyValues?.[0], employeeCount, mobilityStats, 'walk'),
                color: this.themeService.getHexFromCSSVariable('--walk-color'),
                baselineYearly: calculateTopicValues(
                    compareValuesYearly?.[0],
                    employeeCount,
                    compareMobilityStats,
                    'walk',
                    undefined,
                ),
                baselineDaily: calculateTopicValues(
                    compareValuesDaily?.[0],
                    employeeCount,
                    compareMobilityStats,
                    'walk',
                    undefined,
                ),
                unit,
                digitsInfo: '1.0-2',
            },
            {
                title: KpiGridServiceTranslations.modalSplitTopicEntryTitleBike,
                yearly: calculateTopicValues(values?.[1], employeeCount, mobilityStats, 'bike'),
                daily: calculateTopicValues(dailyValues?.[1], employeeCount, mobilityStats, 'bike'),
                color: this.themeService.getHexFromCSSVariable('--bike-color'),
                baselineYearly: calculateTopicValues(
                    compareValuesYearly?.[1],
                    employeeCount,
                    compareMobilityStats,
                    'bike',
                    undefined,
                ),
                baselineDaily: calculateTopicValues(
                    compareValuesDaily?.[1],
                    employeeCount,
                    compareMobilityStats,
                    'bike',
                    undefined,
                ),
                unit,
                digitsInfo: '1.0-2',
            },
            {
                title: KpiGridServiceTranslations.modalSplitTopicEntryTitlePT,
                yearly: calculateTopicValues(values?.[2], employeeCount, mobilityStats, 'transit'),
                daily: calculateTopicValues(
                    dailyValues?.[2],
                    employeeCount,
                    mobilityStats,
                    'transit',
                ),
                color: this.themeService.getHexFromCSSVariable('--public-transport-color'),
                baselineYearly: calculateTopicValues(
                    compareValuesYearly?.[2],
                    employeeCount,
                    compareMobilityStats,
                    'transit',
                    undefined,
                ),
                baselineDaily: calculateTopicValues(
                    compareValuesDaily?.[2],
                    employeeCount,
                    compareMobilityStats,
                    'transit',
                    undefined,
                ),
                unit,
                digitsInfo: '1.0-2',
            },
            {
                title: KpiGridServiceTranslations.modalSplitTopicEntryTitleCarDriver,
                yearly: calculateTopicValues(
                    values?.[3],
                    employeeCount,
                    mobilityStats,
                    'carDriver',
                ),
                daily: calculateTopicValues(
                    dailyValues?.[3],
                    employeeCount,
                    mobilityStats,
                    'carDriver',
                ),
                color: this.themeService.getHexFromCSSVariable('--car-driver-color'),
                baselineYearly: calculateTopicValues(
                    compareValuesYearly?.[3],
                    employeeCount,
                    compareMobilityStats,
                    'carDriver',
                    undefined,
                ),
                baselineDaily: calculateTopicValues(
                    compareValuesDaily?.[3],
                    employeeCount,
                    compareMobilityStats,
                    'carDriver',
                    undefined,
                ),
                unit,
                digitsInfo: '1.0-2',
            },
            {
                title: KpiGridServiceTranslations.modalSplitTopicEntryTitleCarPassenger,
                yearly: calculateTopicValues(
                    values?.[4],
                    employeeCount,
                    mobilityStats,
                    'carPassenger',
                ),
                daily: calculateTopicValues(
                    dailyValues?.[4],
                    employeeCount,
                    mobilityStats,
                    'carPassenger',
                ),
                color: this.themeService.getHexFromCSSVariable('--car-passenger-color'),
                baselineYearly: calculateTopicValues(
                    compareValuesYearly?.[4],
                    employeeCount,
                    compareMobilityStats,
                    'carPassenger',
                    undefined,
                ),
                baselineDaily: calculateTopicValues(
                    compareValuesDaily?.[4],
                    employeeCount,
                    compareMobilityStats,
                    'carPassenger',
                    undefined,
                ),
                unit,
                digitsInfo: '1.0-2',
            },
        ];
    }
}
