import { Injectable } from '@angular/core';
import { Observable, Subject, switchMap } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LoadingService } from '@shared/utils';
import { TaskProgress, TasksService } from '@upscore-mobility-audit/api';
import { UpscoreMobilityAuditUserDataService } from '@upscore-mobility-audit/shared/api-services/user-data.service';

import { TaskCancelledError } from '../errors';
import { ProgressService } from '../interfaces';

import { PollingProgressService } from './polling-progress.service';
import { StompProgressService } from './stomp-progress.service';
import { TaskUtilsService } from './task-utils.service';

@Injectable({
    providedIn: 'root',
})
export class TaskHandlerService {
    public waitingForTask = new Subject<string>();

    private getProgressService: () => ProgressService;

    constructor(
        private tasksService: TasksService,
        private taskUtilsService: TaskUtilsService,
        private loadingService: LoadingService,
        private userDataService: UpscoreMobilityAuditUserDataService,
        websocketService: StompProgressService,
        pollingProgressService: PollingProgressService,
    ) {
        // check which service is available
        this.getProgressService = () => {
            return websocketService.connected ? websocketService : pollingProgressService;
        };
    }

    public subscribeToUserTasks() {
        if (!this.userDataService.user?.id) {
            throw new Error('User not logged in');
        }

        return this.getProgressService().subscribeToAllUserTasks(this.userDataService.user?.id);
    }

    /**
     * Subscribe to task progress only
     * Observable completes when task is completed/failed/cancelled
     * @param taskId
     */
    public subscribeToTasks(taskId: string): Observable<TaskProgress> {
        return this.getProgressService().subscribeToTasks(taskId);
    }

    /**
     * Subscribe to task progress and wait for task completion
     * Task Result is retrieved after completion
     * The result is returned
     * Observable completes when task is completed/failed/cancelled
     * @param taskId
     * @throws TaskCancelledError when task is cancelled
     */
    public waitForTaskCompletion<T>(taskId: string): Observable<T> {
        this.loadingService.startLoading();
        this.waitingForTask.next(taskId);

        return this.subscribeToTasks(taskId).pipe(
            filter(progress => !this.taskUtilsService.taskIsRunning(progress)),
            switchMap(progress => {
                this.loadingService.endLoading();
                if (progress.status === 'CANCELLED') {
                    // we could return some kind of error here
                    throw new TaskCancelledError();
                }

                return this.tasksService.getTaskResult({ taskId });
            }),
            map(result => result as T),
        );
    }
}
