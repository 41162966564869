import { Injectable } from '@angular/core';
import { Observable, pipe, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    MobilityStats,
    ModeEstimationConfigWithCarpoolInfo,
    ModeEstimationService,
} from '@upscore-mobility-audit/api';
import { TaskHandlerService } from '@upscore-mobility-audit/tasks';

@Injectable({
    providedIn: 'root',
})
export class ModeEstimationWrapperService {
    constructor(
        private modeEstimationService: ModeEstimationService,
        private taskHandlerService: TaskHandlerService,
    ) {}

    private static roundStats(stats: MobilityStats): MobilityStats {
        const statsName = Object.keys(stats) as (keyof MobilityStats)[];
        for (const name of statsName) {
            if (typeof stats[name] === 'string') {
                stats[name] = 0.0;
            }
            stats[name] = Math.round(stats[name] * 100);
        }
        const statsArr: number[] = statsName.map(key => stats[key]);
        const arrSum: number = statsArr.reduce((a, b) => a + b, 0);
        if (arrSum !== 100) {
            const indexMax: number = statsArr.indexOf(Math.max(...statsArr));
            stats[statsName[indexMax]] = 100 - (arrSum - statsArr[indexMax]);
        }
        // dividing by 100 after comparison because even if all numbers add up we get 0.999999999
        for (const name of statsName) {
            stats[name] = stats[name] / 100;
        }

        return stats;
    }

    public estimateModalSplitWithConfigAsync(
        locationId: number,
        body: ModeEstimationConfigWithCarpoolInfo,
        improvementOnly: boolean,
        overrideFixedModes: boolean,
    ): Observable<MobilityStats> {
        return this.modeEstimationService
            .estimateWithCustomParamsAsync({
                companyLocationId: locationId,
                body,
                improvementOnly,
                overrideFixedModes,
            })
            .pipe(
                switchMap(task =>
                    this.taskHandlerService.waitForTaskCompletion<MobilityStats>(task.id),
                ),
                pipe(map(stats => ModeEstimationWrapperService.roundStats(stats))),
            );
    }

    public estimateModalSplitAsync(locationId: number): Observable<MobilityStats> {
        return this.modeEstimationService
            .estimateWithBaseParamsAsync({
                companyLocationId: locationId,
            })
            .pipe(
                switchMap(task =>
                    this.taskHandlerService.waitForTaskCompletion<MobilityStats>(task.id),
                ),
                pipe(map(stats => ModeEstimationWrapperService.roundStats(stats))),
            );
    }
}
