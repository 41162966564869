/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CarpoolingIntervention } from '../models/carpooling-intervention';
import { CustomImprovement } from '../models/custom-improvement';
import { EmissionBorderIntervention } from '../models/emission-border-intervention';
import { EstimatedImpact } from '../models/estimated-impact';
import { FreeBikeImprovementSuggestion } from '../models/free-bike-improvement-suggestion';
import { HomeOfficeImprovementSuggestion } from '../models/home-office-improvement-suggestion';
import { JobTicketImprovementSuggestion } from '../models/job-ticket-improvement-suggestion';
import { MobilityScenarioDto } from '../models/mobility-scenario-dto';
import { PackageTask } from '../models/package-task';
import { SmallScoreResponse } from '../models/small-score-response';

@Injectable({
  providedIn: 'root',
})
export class ImprovementsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation editSmallPackage
   */
  static readonly EditSmallPackagePath = '/api/v1/locations/{companyLocationId}/improvements/editSmallPackage/{packageId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editSmallPackage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSmallPackage$Response(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<StrictHttpResponse<SmallScoreResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.EditSmallPackagePath, 'put');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('packageId', params.packageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmallScoreResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editSmallPackage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSmallPackage(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<SmallScoreResponse> {

    return this.editSmallPackage$Response(params).pipe(
      map((r: StrictHttpResponse<SmallScoreResponse>) => r.body as SmallScoreResponse)
    );
  }

  /**
   * Path part for operation editSmallPackageAsync
   */
  static readonly EditSmallPackageAsyncPath = '/api/v1/locations/{companyLocationId}/improvements/async/editSmallPackage/{packageId}';

  /**
   * Update a package and start a calculation for its result.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editSmallPackageAsync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSmallPackageAsync$Response(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<StrictHttpResponse<PackageTask>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.EditSmallPackageAsyncPath, 'put');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.path('packageId', params.packageId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageTask>;
      })
    );
  }

  /**
   * Update a package and start a calculation for its result.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `editSmallPackageAsync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editSmallPackageAsync(params: {
    companyLocationId: number;
    packageId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<PackageTask> {

    return this.editSmallPackageAsync$Response(params).pipe(
      map((r: StrictHttpResponse<PackageTask>) => r.body as PackageTask)
    );
  }

  /**
   * Path part for operation estimateImpact
   */
  static readonly EstimateImpactPath = '/api/v1/locations/{companyLocationId}/improvements/estimateImpact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `estimateImpact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  estimateImpact$Response(params: {
    companyLocationId: number;
    baseScenario: number;
    context?: HttpContext
    body: Array<(CarpoolingIntervention | CustomImprovement | EmissionBorderIntervention | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>
  }
): Observable<StrictHttpResponse<Array<EstimatedImpact>>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.EstimateImpactPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('baseScenario', params.baseScenario, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<EstimatedImpact>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `estimateImpact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  estimateImpact(params: {
    companyLocationId: number;
    baseScenario: number;
    context?: HttpContext
    body: Array<(CarpoolingIntervention | CustomImprovement | EmissionBorderIntervention | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>
  }
): Observable<Array<EstimatedImpact>> {

    return this.estimateImpact$Response(params).pipe(
      map((r: StrictHttpResponse<Array<EstimatedImpact>>) => r.body as Array<EstimatedImpact>)
    );
  }

  /**
   * Path part for operation createSmallPackage
   */
  static readonly CreateSmallPackagePath = '/api/v1/locations/{companyLocationId}/improvements/createSmallPackage';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSmallPackage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSmallPackage$Response(params: {
    companyLocationId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<StrictHttpResponse<SmallScoreResponse>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.CreateSmallPackagePath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SmallScoreResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createSmallPackage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSmallPackage(params: {
    companyLocationId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<SmallScoreResponse> {

    return this.createSmallPackage$Response(params).pipe(
      map((r: StrictHttpResponse<SmallScoreResponse>) => r.body as SmallScoreResponse)
    );
  }

  /**
   * Path part for operation createSmallPackageAsync
   */
  static readonly CreateSmallPackageAsyncPath = '/api/v1/locations/{companyLocationId}/improvements/async/createSmallPackage';

  /**
   * Start a package calculation.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createSmallPackageAsync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSmallPackageAsync$Response(params: {
    companyLocationId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<StrictHttpResponse<PackageTask>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.CreateSmallPackageAsyncPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PackageTask>;
      })
    );
  }

  /**
   * Start a package calculation.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createSmallPackageAsync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createSmallPackageAsync(params: {
    companyLocationId: number;
    context?: HttpContext
    body: MobilityScenarioDto
  }
): Observable<PackageTask> {

    return this.createSmallPackageAsync$Response(params).pipe(
      map((r: StrictHttpResponse<PackageTask>) => r.body as PackageTask)
    );
  }

  /**
   * Path part for operation getSuggestedMeasures
   */
  static readonly GetSuggestedMeasuresPath = '/api/v1/locations/{companyLocationId}/improvements/suggestedMeasures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSuggestedMeasures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedMeasures$Response(params: {
    companyLocationId: number;
    baseScenario: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<(CarpoolingIntervention | CustomImprovement | EmissionBorderIntervention | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>>> {

    const rb = new RequestBuilder(this.rootUrl, ImprovementsService.GetSuggestedMeasuresPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.query('baseScenario', params.baseScenario, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<(CarpoolingIntervention | CustomImprovement | EmissionBorderIntervention | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getSuggestedMeasures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSuggestedMeasures(params: {
    companyLocationId: number;
    baseScenario: number;
    context?: HttpContext
  }
): Observable<Array<(CarpoolingIntervention | CustomImprovement | EmissionBorderIntervention | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>> {

    return this.getSuggestedMeasures$Response(params).pipe(
      map((r: StrictHttpResponse<Array<(CarpoolingIntervention | CustomImprovement | EmissionBorderIntervention | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>>) => r.body as Array<(CarpoolingIntervention | CustomImprovement | EmissionBorderIntervention | FreeBikeImprovementSuggestion | HomeOfficeImprovementSuggestion | JobTicketImprovementSuggestion)>)
    );
  }

}
