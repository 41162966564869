import { Pipe, PipeTransform } from '@angular/core';

import { InterventionType } from '@upscore-mobility-audit/core/interfaces/intervention-type.interface';

@Pipe({
    name: 'measureColor',
    standalone: true,
})
export class MeasureColorPipe implements PipeTransform {
    transform(interventionType: InterventionType): string {
        switch (interventionType) {
            case 'CarpoolingIntervention':
                return '--car-passenger-color';
            case 'JobTicketImprovementSuggestion':
                return '--public-transport-color';
            case 'FreeBikeImprovementSuggestion':
                return '--bike-color';
            case 'HomeOfficeImprovementSuggestion':
            case 'CustomImprovement':
                return '--primary-color';
            case 'EmissionBorderIntervention':
            case '__OTHER__':
            default:
                return '--beta-color';
        }
    }
}
