<h2 mat-dialog-title>Register for Notification</h2>
<mat-dialog-content>
    <div class="my-m">You will receive an email notification when the task is complete.</div>
    <div [formGroup]="form" class="flex flex-row gap-s">
        <mat-form-field>
            <mat-label>Email</mat-label>
            <input
                matInput
                i18n-placeholder="@@enterYourEmail"
                placeholder="Enter your email"
                formControlName="email"
                required
                type="email" />
            <mat-error
                *ngIf="form.controls['email']?.invalid && form.controls['email']?.touched"
                i18n="@@enterValidEmail">
                Please enter a valid email address.
            </mat-error>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Language</mat-label>
            <mat-select
                i18n-placeholder="@@language"
                placeholder="Language"
                formControlName="languageCode"
                [value]="form.value.languageCode"
                required>
                @for (option of languages | keyvalue; track option.key) {
                    <mat-option [value]="option.key.toLowerCase()">
                        {{ option.value?.name }}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close i18n="@@cancel">Cancel</button>
    <button
        mat-stroked-button
        color="primary"
        mat-dialog-close
        (click)="notifyUser(form.value.email!, form.value.languageCode!)"
        [disabled]="!form.valid"
        cdkFocusInitial
        i18n="@@register">
        Register
    </button>
</mat-dialog-actions>
