/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CalculationProperties } from '../models/calculation-properties';

@Injectable({
  providedIn: 'root',
})
export class MobilityPropertiesService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getUserMobilityProperties
   */
  static readonly GetUserMobilityPropertiesPath = '/api/v1/userMobilityProperties';

  /**
   * get user mobility properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserMobilityProperties()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserMobilityProperties$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CalculationProperties>> {

    const rb = new RequestBuilder(this.rootUrl, MobilityPropertiesService.GetUserMobilityPropertiesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationProperties>;
      })
    );
  }

  /**
   * get user mobility properties.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getUserMobilityProperties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserMobilityProperties(params?: {
    context?: HttpContext
  }
): Observable<CalculationProperties> {

    return this.getUserMobilityProperties$Response(params).pipe(
      map((r: StrictHttpResponse<CalculationProperties>) => r.body as CalculationProperties)
    );
  }

  /**
   * Path part for operation updateUserMobilityProperties
   */
  static readonly UpdateUserMobilityPropertiesPath = '/api/v1/userMobilityProperties';

  /**
   * Update mobilityProperties for the user.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateUserMobilityProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserMobilityProperties$Response(params: {
    context?: HttpContext
    body: CalculationProperties
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MobilityPropertiesService.UpdateUserMobilityPropertiesPath, 'put');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * Update mobilityProperties for the user.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateUserMobilityProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateUserMobilityProperties(params: {
    context?: HttpContext
    body: CalculationProperties
  }
): Observable<void> {

    return this.updateUserMobilityProperties$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setUserMobilityProperties
   */
  static readonly SetUserMobilityPropertiesPath = '/api/v1/userMobilityProperties';

  /**
   * set user mobility properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setUserMobilityProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserMobilityProperties$Response(params: {
    context?: HttpContext
    body: CalculationProperties
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MobilityPropertiesService.SetUserMobilityPropertiesPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * set user mobility properties.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setUserMobilityProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setUserMobilityProperties(params: {
    context?: HttpContext
    body: CalculationProperties
  }
): Observable<void> {

    return this.setUserMobilityProperties$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation getLocationMobilityProperties
   */
  static readonly GetLocationMobilityPropertiesPath = '/api/v1/locations/{companyLocationId}/mobilityProperties';

  /**
   * get location mobility properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLocationMobilityProperties()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationMobilityProperties$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CalculationProperties>> {

    const rb = new RequestBuilder(this.rootUrl, MobilityPropertiesService.GetLocationMobilityPropertiesPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CalculationProperties>;
      })
    );
  }

  /**
   * get location mobility properties.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLocationMobilityProperties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLocationMobilityProperties(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<CalculationProperties> {

    return this.getLocationMobilityProperties$Response(params).pipe(
      map((r: StrictHttpResponse<CalculationProperties>) => r.body as CalculationProperties)
    );
  }

  /**
   * Path part for operation updateLocationMobilityProperties
   */
  static readonly UpdateLocationMobilityPropertiesPath = '/api/v1/locations/{companyLocationId}/mobilityProperties';

  /**
   * update location mobility properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLocationMobilityProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLocationMobilityProperties$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
    body: CalculationProperties
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MobilityPropertiesService.UpdateLocationMobilityPropertiesPath, 'put');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * update location mobility properties.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLocationMobilityProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLocationMobilityProperties(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
    body: CalculationProperties
  }
): Observable<void> {

    return this.updateLocationMobilityProperties$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation setLocationMobilityProperties
   */
  static readonly SetLocationMobilityPropertiesPath = '/api/v1/locations/{companyLocationId}/mobilityProperties';

  /**
   * persist location mobility properties.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setLocationMobilityProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setLocationMobilityProperties$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
    body: CalculationProperties
  }
): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MobilityPropertiesService.SetLocationMobilityPropertiesPath, 'post');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * persist location mobility properties.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `setLocationMobilityProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  setLocationMobilityProperties(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
    body: CalculationProperties
  }
): Observable<void> {

    return this.setLocationMobilityProperties$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
