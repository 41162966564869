import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule, DecimalPipe, NgOptimizedImage } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { RouterModule } from '@angular/router';
import { NgxMasonryModule } from 'ngx-masonry';

import { ModeTextTranslatePipe } from '@shared/utils';
import { CompanyLocationHeaderComponent } from '@ui-library/components/company-location-header/company-location-header.component';
import { InfoComponent } from '@ui-library/components/info/info.component';
import { MaterialModule } from '@ui-library/modules/material/material.module';

import { ErrorCancelContinueDialogComponent } from './dialogs/error-cancel-continue-dialog/error-cancel-continue-dialog.component';
import { CastPipe } from './pipes/cast.pipe';
import { DailyKpiPipe } from './pipes/daily-kpi.pipe';
import { FormControlPipe } from './pipes/form-control.pipe';
import { FormGroupPipe } from './pipes/form-group.pipe';
import { PercentageChangePipe } from './pipes/percentage-change.pipe';
import { ScenarioReferenceByStepPipe } from './pipes/scenario-reference-by-step.pipe';

@NgModule({
    declarations: [
        DailyKpiPipe,
        PercentageChangePipe,
        FormControlPipe,
        FormGroupPipe,
        ErrorCancelContinueDialogComponent,
        CastPipe,
        ScenarioReferenceByStepPipe,
    ],
    providers: [
        DecimalPipe,
        // { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategyService },
    ],
    imports: [
        CommonModule,
        HttpClientModule,
        RouterModule,
        MaterialModule,
        CdkStepperModule,
        NgxMasonryModule,
        FormsModule,
        ReactiveFormsModule,
        MatAutocompleteModule,
        NgOptimizedImage,
        CompanyLocationHeaderComponent,
        InfoComponent,
        ModeTextTranslatePipe,
    ],
    exports: [
        RouterModule,
        MaterialModule,
        CdkStepperModule,
        CommonModule,
        NgxMasonryModule,
        DailyKpiPipe,
        PercentageChangePipe,
        ModeTextTranslatePipe,
        FormControlPipe,
        FormGroupPipe,
        ErrorCancelContinueDialogComponent,
        CastPipe,
        ScenarioReferenceByStepPipe,
    ],
})
export class UpscoreSharedModule {}
