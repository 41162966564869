import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {
    CompanyLocation,
    CompanyLocationToChange,
    CompanyLocationsService,
    DashboardLocationDto,
    PostedCompanyLocation,
} from '@upscore-mobility-audit/api';

@Injectable({
    providedIn: 'root',
})
export class CompanyLocationsWrapperService {
    constructor(private companyLocationsService: CompanyLocationsService) {}

    /**
     * Method to post new locations
     * @params
     */
    public createLocation(params: { body: PostedCompanyLocation }): Observable<CompanyLocation> {
        return this.companyLocationsService.newCompanyLocation(params);
    }

    /**
     * Method to patch location
     * @params
     */
    public updateLocation(params: {
        companyLocationId: number;
        body: CompanyLocationToChange;
    }): Observable<CompanyLocation> {
        return this.companyLocationsService.updateCompanyLocation(params);
    }

    /**
     * Method to get company location with a provided id
     * @param locationId
     */
    public getCompanyLocation(locationId: number): Observable<CompanyLocation> {
        const params: { companyLocationId: number } = {
            companyLocationId: locationId,
        };

        return this.companyLocationsService.companyLocation(params);
    }

    /**
     * Method to get minimal location with a provided id
     * @param locationId
     */
    public getMinimalCompanyLocation(locationId: number): Observable<DashboardLocationDto> {
        const params: { id: number } = {
            id: locationId,
        };

        return this.companyLocationsService.getCompanyLocationMinimal(params);
    }

    /**
     * Method to delete company location with a provided id
     * @param locationId
     */
    public deleteCompanyLocation(locationId: number): Observable<string> {
        const params: { companyLocationId: number } = {
            companyLocationId: locationId,
        };

        return this.companyLocationsService.deleteCompanyLocation(params);
    }

    public getMinimalCompanyLocations(params: {
        offset: number;
        limit: number;
        searchQuery: string;
        industry: string;
    }) {
        return this.companyLocationsService.allCompanyLocationsMinimal(params);
    }

    public getAllIndustries() {
        return this.companyLocationsService.getAllIndustries();
    }
}
