<mat-icon
    [class.material-symbols-outlined]="measureType === 'CustomImprovement'"
    [class.mini-icon]="size === 'small'"
    [class.large-icon]="size === 'large'"
    class="text-white text-center">
    @switch (measureType) {
        @case ('CarpoolingIntervention') {
            directions_car
        }
        @case ('JobTicketImprovementSuggestion') {
            train
        }
        @case ('FreeBikeImprovementSuggestion') {
            directions_bike
        }
        @case ('HomeOfficeImprovementSuggestion') {
            home
        }
        @case ('CustomImprovement') {
            tactic
        }
        @case ('__OTHER__') {
            emoji_objects
        }
        @case ('EmissionBorderIntervention') {
            auto_awesome
        }
        @default {
            auto_awesome
        }
    }
</mat-icon>
