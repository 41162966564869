import { Component, Injector, OnDestroy, OnInit, computed, input, output } from '@angular/core';
import { cloneDeep } from 'lodash-es';
import { Subject, debounceTime } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ThemeService } from '@shared/utils';
import {
    CompanyLocation,
    Employee,
    EstimatedImpact,
    ImprovementParameterObject,
    MobilityIntervention,
} from '@upscore-mobility-audit/api';
import { ParamEnabled } from '@upscore-mobility-audit/data-collection/components/parameters/base-parameter.component';
import { Category } from '@upscore-mobility-audit/data-collection/components/parameters/measure-category-parameter/measure-category-parameter.component';
import { SliderParameter } from '@upscore-mobility-audit/data-collection/components/parameters/measure-min-work-days-parameter/measure-min-work-days-parameter.component';

import { Modes } from '../../interfaces/criteria-classes';
import { CriteriaManager } from '../../interfaces/criteria-manager.class';

const targetParameterNames: string[] = [
    'maxDistanceToCompany',
    'filterEmployees',
    'minWorkDays',
    'minDistanceToCompany',
    'cutOffTransitDurationComparedToCar',
];

@Component({
    selector: 'detailed-measure-suggestion',
    templateUrl: './detailed-measure-suggestion.component.html',
    styleUrls: ['./detailed-measure-suggestion.component.scss'],
})
export class DetailedMeasureSuggestionComponent implements OnInit, OnDestroy {
    public employeeCategories = input.required<Category[]>();

    public addSuggestion = output<[MobilityIntervention, MobilityIntervention]>();

    public parametersChanged = output<MobilityIntervention['parameters']>();

    public originalSuggestion = input.required<MobilityIntervention>();

    public estimatedImpact = input<EstimatedImpact>();

    public suggestion = computed(() => {
        const suggestion = cloneDeep(this.originalSuggestion());
        const availableCategories = this.employeeCategories();
        // Add (disabled) filterEmployees parameter if it doesn't exist
        if (
            suggestion.parameters.filter(it => it.type == 'FilterEmployeesParameter').length == 0 &&
            availableCategories.length > 0
        ) {
            suggestion.parameters.push({
                type: 'FilterEmployeesParameter',
                name: 'filterEmployees',
                value: {
                    field: availableCategories[0].category,
                    validValues: [availableCategories[0].entries[0].value],
                },
                enabled: false,
            } as ImprovementParameterObject);
        }

        // Add (disabled) min work days parameter if it doesn't exist
        if (
            suggestion.type === 'HomeOfficeImprovementSuggestion' &&
            suggestion.parameters.filter(it => it.name == 'minWorkDays').length == 0
        ) {
            suggestion.parameters.push({
                type: 'SliderParameter',
                name: 'minWorkDays',
                value: 3.0,
                min: 0.0,
                step: 0.1,
                max: 5.0,
                unit: 'days',
                enabled: false,
            } as ImprovementParameterObject & SliderParameter);
        }

        return suggestion;
    });

    public targetParameters = computed(() => {
        return this.suggestion().parameters.filter(it => targetParameterNames.includes(it.name));
    });

    public causedChangeParameters = computed(() => {
        return this.suggestion().parameters.filter(it => !targetParameterNames.includes(it.name));
    });

    public nameParam = computed(() => {
        return this.suggestion().parameters.find(it => it.name === 'customName');
    });

    public modes: typeof Modes = Modes;

    public readonly criteriaManager: CriteriaManager;

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    private readonly _changed = new Subject<void>();

    constructor(
        private readonly themeService: ThemeService,
        injector: Injector,
    ) {
        this.criteriaManager = new CriteriaManager(this.themeService, injector);
        this.criteriaManager.enableMode(Modes.PublicTransport);
        this._changed
            .pipe(takeUntil(this.unsubscribe$), debounceTime(300))
            .subscribe(() =>
                this.parametersChanged.emit(
                    this.suggestion().parameters.filter(p => (p as ParamEnabled).enabled ?? true),
                ),
            );
    }

    public onAddSuggestion(): void {
        this.suggestion().parameters = this.suggestion().parameters.filter(
            p => (p as ParamEnabled).enabled ?? true,
        );
        this.addSuggestion.emit([this.originalSuggestion(), this.suggestion()]);
    }

    public ngOnInit(): void {
        this.paramChanged();
    }

    public paramChanged(): void {
        this._changed.next();
    }

    public ngOnDestroy(): void {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
}

export function computeEmployeeCategories(employees: Employee[], location: CompanyLocation) {
    const departments = new Set<string>();
    const shifts = new Set<string>();
    employees.forEach(employee => {
        if (employee.department) {
            departments.add(employee.department);
        }
        if (employee.shift) {
            shifts.add(employee.shift);
        }
    });
    const entrances = location.entrances;

    const categories: Category[] = [];
    if (departments.size > 0) {
        categories.push({
            category: 'DEPARTMENT',
            entries: Array.from(departments).map(department => ({
                value: department,
            })),
        });
    }
    if (entrances.length > 1) {
        categories.push({
            category: 'ENTRANCE_NUMBER',
            entries: entrances.map(entrance => ({
                value: entrance.number.toString(),
                displayText: `${entrance.location.street} ${entrance.location.city}`,
            })),
        });
    }
    if (shifts.size > 1) {
        categories.push({
            category: 'SHIFT_MODEL',
            entries: Array.from(shifts).map(shift => ({
                value: shift,
            })),
        });
    }

    return categories;
}
