/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { CompanyLocation } from '../models/company-location';
import { CompanyLocationToChange } from '../models/company-location-to-change';
import { DashboardLocationDto } from '../models/dashboard-location-dto';
import { MobilityStats } from '../models/mobility-stats';
import { PagingResponseDashboardLocationDto } from '../models/paging-response-dashboard-location-dto';
import { PostedCompanyLocation } from '../models/posted-company-location';

@Injectable({
  providedIn: 'root',
})
export class CompanyLocationsService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation newCompanyLocation
   */
  static readonly NewCompanyLocationPath = '/api/v1/locations';

  /**
   * create company location.
   *
   * Mobility statistics (modal split + car utilization) and vehicleStats are set to sensible defaults whenever a new company is created and these objects aren't specified. Both Modal split and vehicleStat values are given as percentages and must sum up to 1.This defaults can either be set through a per-country pre-defined dataset or on a more granular level (e.g. per city) in the future.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `newCompanyLocation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newCompanyLocation$Response(params: {
    context?: HttpContext
    body: PostedCompanyLocation
  }
): Observable<StrictHttpResponse<CompanyLocation>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.NewCompanyLocationPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocation>;
      })
    );
  }

  /**
   * create company location.
   *
   * Mobility statistics (modal split + car utilization) and vehicleStats are set to sensible defaults whenever a new company is created and these objects aren't specified. Both Modal split and vehicleStat values are given as percentages and must sum up to 1.This defaults can either be set through a per-country pre-defined dataset or on a more granular level (e.g. per city) in the future.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `newCompanyLocation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  newCompanyLocation(params: {
    context?: HttpContext
    body: PostedCompanyLocation
  }
): Observable<CompanyLocation> {

    return this.newCompanyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocation>) => r.body as CompanyLocation)
    );
  }

  /**
   * Path part for operation companyLocation
   */
  static readonly CompanyLocationPath = '/api/v1/locations/{companyLocationId}';

  /**
   * Get information about a company location.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocation$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<CompanyLocation>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.CompanyLocationPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocation>;
      })
    );
  }

  /**
   * Get information about a company location.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocation(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<CompanyLocation> {

    return this.companyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocation>) => r.body as CompanyLocation)
    );
  }

  /**
   * Path part for operation deleteCompanyLocation
   */
  static readonly DeleteCompanyLocationPath = '/api/v1/locations/{companyLocationId}';

  /**
   * Delete company location.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCompanyLocation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyLocation$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.DeleteCompanyLocationPath, 'delete');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Delete company location.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `deleteCompanyLocation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCompanyLocation(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.deleteCompanyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation updateCompanyLocation
   */
  static readonly UpdateCompanyLocationPath = '/api/v1/locations/{companyLocationId}';

  /**
   * update a  company locations.
   *
   * Update a part of the companyLocation. Only fields that are actually present in the request will be updated, the rest will stay the same. 
   *
   * Updates to mobilityStats, vehicleStats or presenceDays will delete the currently saved mobilityScore of the company.
   * Note, that when updating entrances, you can either not update any entrance by leaving out the entrance property, or update all entrances by adding them all. Furthermore, right now with this REST service entrances can't be added or deleted.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCompanyLocation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyLocation$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
    body: CompanyLocationToChange
  }
): Observable<StrictHttpResponse<CompanyLocation>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.UpdateCompanyLocationPath, 'patch');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CompanyLocation>;
      })
    );
  }

  /**
   * update a  company locations.
   *
   * Update a part of the companyLocation. Only fields that are actually present in the request will be updated, the rest will stay the same. 
   *
   * Updates to mobilityStats, vehicleStats or presenceDays will delete the currently saved mobilityScore of the company.
   * Note, that when updating entrances, you can either not update any entrance by leaving out the entrance property, or update all entrances by adding them all. Furthermore, right now with this REST service entrances can't be added or deleted.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateCompanyLocation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCompanyLocation(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
    body: CompanyLocationToChange
  }
): Observable<CompanyLocation> {

    return this.updateCompanyLocation$Response(params).pipe(
      map((r: StrictHttpResponse<CompanyLocation>) => r.body as CompanyLocation)
    );
  }

  /**
   * Path part for operation companyLocationPresenceDaysSuggestions
   */
  static readonly CompanyLocationPresenceDaysSuggestionsPath = '/api/v1/locations/{companyLocationId}/presenceDays/suggestions';

  /**
   * Get presenceDays suggestion.
   *
   * Get estimated presenceDays in the company based on previously uploaded detailed employee data.
   * Calculated by averaging the workTime of all uploaded employees and dividing by an assumed average work time of 8 hours per day given that all employees work their whole time at the companyLocation (e.g. no home office). <br />Depends on at least one employee in the company having the workTime attribute set.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocationPresenceDaysSuggestions()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationPresenceDaysSuggestions$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<string>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.CompanyLocationPresenceDaysSuggestionsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<string>;
      })
    );
  }

  /**
   * Get presenceDays suggestion.
   *
   * Get estimated presenceDays in the company based on previously uploaded detailed employee data.
   * Calculated by averaging the workTime of all uploaded employees and dividing by an assumed average work time of 8 hours per day given that all employees work their whole time at the companyLocation (e.g. no home office). <br />Depends on at least one employee in the company having the workTime attribute set.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocationPresenceDaysSuggestions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationPresenceDaysSuggestions(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<string> {

    return this.companyLocationPresenceDaysSuggestions$Response(params).pipe(
      map((r: StrictHttpResponse<string>) => r.body as string)
    );
  }

  /**
   * Path part for operation companyLocationModalSplitSuggestions
   */
  static readonly CompanyLocationModalSplitSuggestionsPath = '/api/v1/locations/{companyLocationId}/modalSplit/suggestions';

  /**
   * Get optimal modal split estimate for company.
   *
   * Get estimated optimal modal split based on employee locations that can be used as default values for packages or company modal split.<br />Keep in mind this function only works when employees are present and can take some time if employee travel distances and times need to be calculated first.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `companyLocationModalSplitSuggestions()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationModalSplitSuggestions$Response(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MobilityStats>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.CompanyLocationModalSplitSuggestionsPath, 'get');
    if (params) {
      rb.path('companyLocationId', params.companyLocationId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MobilityStats>;
      })
    );
  }

  /**
   * Get optimal modal split estimate for company.
   *
   * Get estimated optimal modal split based on employee locations that can be used as default values for packages or company modal split.<br />Keep in mind this function only works when employees are present and can take some time if employee travel distances and times need to be calculated first.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `companyLocationModalSplitSuggestions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  companyLocationModalSplitSuggestions(params: {

    /**
     * company location id
     */
    companyLocationId: number;
    context?: HttpContext
  }
): Observable<MobilityStats> {

    return this.companyLocationModalSplitSuggestions$Response(params).pipe(
      map((r: StrictHttpResponse<MobilityStats>) => r.body as MobilityStats)
    );
  }

  /**
   * Path part for operation allCompanyLocationsMinimal
   */
  static readonly AllCompanyLocationsMinimalPath = '/api/v1/locations/minimal';

  /**
   * Get all company locations with minimal responses.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `allCompanyLocationsMinimal()` instead.
   *
   * This method doesn't expect any request body.
   */
  allCompanyLocationsMinimal$Response(params?: {

    /**
     * number of results to return
     */
    limit?: number;

    /**
     * number of results to skip
     */
    offset?: number;

    /**
     * search query (or empty)
     */
    searchQuery?: string;

    /**
     * industry (or empty)
     */
    industry?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<PagingResponseDashboardLocationDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.AllCompanyLocationsMinimalPath, 'get');
    if (params) {
      rb.query('limit', params.limit, {});
      rb.query('offset', params.offset, {});
      rb.query('searchQuery', params.searchQuery, {});
      rb.query('industry', params.industry, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PagingResponseDashboardLocationDto>;
      })
    );
  }

  /**
   * Get all company locations with minimal responses.
   *
   *
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `allCompanyLocationsMinimal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  allCompanyLocationsMinimal(params?: {

    /**
     * number of results to return
     */
    limit?: number;

    /**
     * number of results to skip
     */
    offset?: number;

    /**
     * search query (or empty)
     */
    searchQuery?: string;

    /**
     * industry (or empty)
     */
    industry?: string;
    context?: HttpContext
  }
): Observable<PagingResponseDashboardLocationDto> {

    return this.allCompanyLocationsMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<PagingResponseDashboardLocationDto>) => r.body as PagingResponseDashboardLocationDto)
    );
  }

  /**
   * Path part for operation getCompanyLocationMinimal
   */
  static readonly GetCompanyLocationMinimalPath = '/api/v1/locations/minimal/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCompanyLocationMinimal()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyLocationMinimal$Response(params: {

    /**
     * company location id
     */
    id: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<DashboardLocationDto>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.GetCompanyLocationMinimalPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<DashboardLocationDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getCompanyLocationMinimal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCompanyLocationMinimal(params: {

    /**
     * company location id
     */
    id: number;
    context?: HttpContext
  }
): Observable<DashboardLocationDto> {

    return this.getCompanyLocationMinimal$Response(params).pipe(
      map((r: StrictHttpResponse<DashboardLocationDto>) => r.body as DashboardLocationDto)
    );
  }

  /**
   * Path part for operation getAllIndustries
   */
  static readonly GetAllIndustriesPath = '/api/v1/locations/all-industries';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllIndustries()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllIndustries$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<string>>> {

    const rb = new RequestBuilder(this.rootUrl, CompanyLocationsService.GetAllIndustriesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<string>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getAllIndustries$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllIndustries(params?: {
    context?: HttpContext
  }
): Observable<Array<string>> {

    return this.getAllIndustries$Response(params).pipe(
      map((r: StrictHttpResponse<Array<string>>) => r.body as Array<string>)
    );
  }

}
