import { OptimumPageTranslations } from '@upscore-mobility-audit/core/translations/optimum-page-translations';
import { StatusQuoPageTranslations } from '@upscore-mobility-audit/core/translations/status-quo-page-translations';

export class KpiCard {
    public static readonly MOBILITY_SCORE: KpiCardInfo = {
        title: 'Mobility Score',
        userTypes: ['PREMIUM'],
        size: 2,
    };
    public static readonly CAR_OCCUPANCY_STATUS_QUO: KpiCardInfo = {
        title: StatusQuoPageTranslations.motorizedIndividualTransportTitle + ' - Status Quo',
        userTypes: ['PREMIUM', 'LIGHT'],
        size: 1,
    };
    public static readonly MODAL_SPLIT_STATUS_QUO: KpiCardInfo = {
        title: StatusQuoPageTranslations.modalSplitTitle + ' - Status Quo',
        userTypes: ['PREMIUM', 'LIGHT'],
        size: 2,
    };
    public static readonly CO2_EMISSIONS_STATUS_QUO: KpiCardInfo = {
        title: StatusQuoPageTranslations.co2EmissionsTitle + ' - Status Quo',
        userTypes: ['PREMIUM', 'LIGHT'],
        size: 3,
        expandedSize: 5,
    };
    public static readonly EMPLOYER_COSTS_STATUS_QUO: KpiCardInfo = {
        title: StatusQuoPageTranslations.employerCostsTitle + ' - Status Quo',
        userTypes: ['PREMIUM', 'LIGHT'],
        size: 1,
    };
    public static readonly EMPLOYEE_COSTS_STATUS_QUO: KpiCardInfo = {
        title: StatusQuoPageTranslations.employeeCostsTitle + ' - Status Quo',
        userTypes: ['PREMIUM', 'LIGHT'],
        size: 3,
        expandedSize: 5,
    };
    public static readonly TRAVEL_DISTANCE_STATUS_QUO: KpiCardInfo = {
        title: StatusQuoPageTranslations.tripDistanceTitle + ' - Status Quo',
        userTypes: ['PREMIUM'],
        size: 3,
        expandedSize: 5,
    };
    public static readonly TRAVEL_TIME_STATUS_QUO: KpiCardInfo = {
        title: StatusQuoPageTranslations.tripDurationTitle + ' - Status Quo',
        userTypes: ['PREMIUM'],
        size: 3,
        expandedSize: 5,
    };
    public static readonly AVG_PER_EMPLOYEE_STATUS_QUO: KpiCardInfo = {
        title: StatusQuoPageTranslations.avgEmployeeTitle + ' - Status Quo',
        userTypes: ['PREMIUM'],
        size: 2,
    };
    public static readonly MODAL_SPLIT_OPTIMUM: KpiCardInfo = {
        title: OptimumPageTranslations.modalSplitTitle + ' - Optimum',
        userTypes: ['PREMIUM', 'LIGHT'],
        size: 4,
    };
    public static readonly CAR_OCCUPANCY_OPTIMUM: KpiCardInfo = {
        title: OptimumPageTranslations.carOccupancyTitle + ' - Optimum',
        userTypes: ['PREMIUM', 'LIGHT'],
        size: 1,
    };
    public static readonly CO2_EMISSIONS_OPTIMUM: KpiCardInfo = {
        title: OptimumPageTranslations.co2EmissionText + ' - Optimum',
        userTypes: ['PREMIUM', 'LIGHT'],
        size: 3,
        expandedSize: 5,
    };
    public static readonly EMPLOYER_COSTS_OPTIMUM: KpiCardInfo = {
        title: OptimumPageTranslations.businessCostsText + ' - Optimum',
        userTypes: ['PREMIUM', 'LIGHT'],
        size: 1,
    };
    public static readonly EMPLOYEE_COSTS_OPTIMUM: KpiCardInfo = {
        title: OptimumPageTranslations.employeeCostsText + ' - Optimum',
        userTypes: ['PREMIUM', 'LIGHT'],
        size: 3,
        expandedSize: 5,
    };
    public static readonly TRAVEL_DISTANCE_OPTIMUM: KpiCardInfo = {
        title: OptimumPageTranslations.travelDistanceText + ' - Optimum',
        userTypes: ['PREMIUM'],
        size: 3,
        expandedSize: 5,
    };
    public static readonly TRAVEL_TIME_OPTIMUM: KpiCardInfo = {
        title: OptimumPageTranslations.travelTimeText + ' - Optimum',
        userTypes: ['PREMIUM'],
        size: 3,
        expandedSize: 5,
    };
    public static readonly AVG_PER_EMPLOYEE_OPTIMUM: KpiCardInfo = {
        title: OptimumPageTranslations.avgPerEmployeeText + ' - Optimum',
        userTypes: ['PREMIUM'],
        size: 3,
    };
}

export interface KpiCardInfo {
    title: string;
    userTypes: Array<'PREMIUM' | 'LIGHT'>;
    size: number;
    expandedSize?: number;
}
